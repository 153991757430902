import { type User } from '@auth0/auth0-spa-js'
import { get, has, set } from 'lodash'
import { type ReplyConsentsPersonalInformation, type CredentialInfo } from '../../../aee_types/src/types'
import { type DatabaseStudyRecordInfo } from '../../../aee_types/src/databaseTypes'

export const METADATA_PREFIX = 'app_metadata.'
export const IDTOKEN_CUSTOM_METADATA_NAMESPACE = 'http://aaltoee.fi'

type HideProfileMapKey = keyof Omit<HideProfile, 'fullName' | 'companies' | 'roles' | 'idTokenMetadata'>

const hideValueToExternalValueMap: Record<HideProfileMapKey, string> = {
  firstName: `${METADATA_PREFIX}firstName`,
  lastName: `${METADATA_PREFIX}lastName`,
  middleName: `${METADATA_PREFIX}middleName`,
  birthDate: `${METADATA_PREFIX}birthDate`,
  personalId: `${METADATA_PREFIX}personalId`,
  gender: `${METADATA_PREFIX}gender`,
  nationality: `${METADATA_PREFIX}nationality`,
  otherNationality: `${METADATA_PREFIX}otherNationality`,
  company: `${METADATA_PREFIX}company`,
  jobTitle: `${METADATA_PREFIX}jobTitle`,
  phone: `${METADATA_PREFIX}phone`,
  additionalPhoneNumbers: `${METADATA_PREFIX}additionalPhoneNumbers`,
  address: `${METADATA_PREFIX}address`,
  companyInformation: `${METADATA_PREFIX}companyInformation`,
  isStronglyAuthenticated: `${METADATA_PREFIX}isStronglyAuthenticated`,
  lang: `${METADATA_PREFIX}lang`,
  updatedAt: 'updated_at', // Auth0 updated_at field
  email: 'email',
  emailVerified: 'email_verified',
  userId: 'user_id',
  marketingConsentGiven: `${METADATA_PREFIX}marketingConsentGiven`,
  marketingConsentTimestamp: `${METADATA_PREFIX}marketingConsentTimestamp`,
  termsOfServiceConsentGiven: `${METADATA_PREFIX}termsOfServiceConsentGiven`,
  termsOfServiceConsentTimestamp: `${METADATA_PREFIX}termsOfServiceConsentTimestamp`,
  additionalEmails: `${METADATA_PREFIX}additional_emails`,
  modifiedAt: `${METADATA_PREFIX}modifiedAt`, // Manually added field
  credentials: 'credentials',
  studyRecords: 'studyRecords',
  consents: 'consents'
}

/**
 * Transforms external profile to HideProfile.
 */
const transformExternalProfileToHideProfile = (externalProfile: User): HideProfile => {
  const profile = new HideProfile()
  const keys = Object.keys(hideValueToExternalValueMap) as HideProfileMapKey[]

  keys.forEach((prop: HideProfileMapKey) => {
    if (has(externalProfile, hideValueToExternalValueMap[prop])) {
      const value = get(externalProfile, hideValueToExternalValueMap[prop])
      set(profile, prop, value)
    }
  })

  profile.userId = profile.userId ?? externalProfile.sub

  if (has(externalProfile, IDTOKEN_CUSTOM_METADATA_NAMESPACE)) {
    profile.idTokenMetadata = get(externalProfile, IDTOKEN_CUSTOM_METADATA_NAMESPACE)
  }

  return profile
}

/**
 * Transforms HideProfile to external profile.
 */
export const transformHideProfileToExternalProfile = (profile: Partial<HideProfile>): Record<string, any> => {
  const externalProfile: Record<string, any> = {}
  const keys = Object.keys(hideValueToExternalValueMap) as HideProfileMapKey[]

  keys.forEach((prop: HideProfileMapKey) => {
    if (has(profile, prop)) {
      const value = get(profile, prop)
      set(externalProfile, hideValueToExternalValueMap[prop], value)
    }
  })

  return externalProfile
}

/**
 * Helper functions that allow to safely manipulate HideProfile entity regardless of it's composition.
 * Depending on the context, HideProfile can be inflated from different sources, and have different properties.
 */

/**
 * Returns profile of a generic user.
 * Should be called when generating Profile out of mgmt->user requests.
 * @param userInfo
 * @param roles
 * @returns HideProfile
 */
export const inflateGenericUser = (
  userInfo: User,
  roles: string[] = [],
  companies: any[] = [],
  credentials: any = [],
  studyRecords: any[] = [],
  consents: any[] = []
): HideProfile => {
  const profile = transformExternalProfileToHideProfile(userInfo)

  // Set full name as a combination of first and last name
  const firstName = profile.firstName ?? ''
  const lastName = profile.lastName ?? ''
  profile.fullName = [firstName, lastName].map(names => names.trim()).filter(x => x).join(' ')

  // Add roles, companies, credentials, study records and consents to profile
  profile.roles = roles
  profile.companies = companies
  profile.credentials = credentials
  profile.studyRecords = studyRecords
  profile.consents = consents
  return profile
}

/**
 * Representation of a Profile at IAM.
 * Simple value-object. Should be utilized with Profiler helper methods to ensure that even if Representation changes - usage staus stable.
 */

export interface CompanyInformation {
  companyName?: string
  taxResidency?: string
  businessId?: string
  unitOrDepartment?: string
  eInvoicingAddress?: string
  eInvoicingOperator?: string
  purchaseOrderNumber?: string
  invoicingReference?: string
  invoiceAddress?: Address
}

export interface Address {
  street?: string
  zipcode?: string
  city?: string
  country?: string
}

export type AdditionalEmails = Array<{ email: string, verified: boolean }>
export interface Company { metadata: { portal_type?: string, [key: string]: any }, roles: Array<{ name: string, [key: string]: any }>, [key: string]: any }
export type Companies = Company[]

export class HideProfile {
  firstName: string = ''
  middleName: string = ''
  lastName: string = ''
  userId: string = ''
  roles: string[] = []
  companies: Companies = []
  fullName: string = ''
  updatedAt: string = ''
  email: string = ''
  emailVerified: boolean = false
  additionalEmails: AdditionalEmails = []
  birthDate: string = ''
  personalId: string = ''
  gender: string = ''
  nationality: string = ''
  otherNationality?: string = ''
  company: string = ''
  jobTitle: string = ''
  phone?: string = ''
  additionalPhoneNumbers?: string[] = []
  address?: Address = {}
  idTokenMetadata?: Record<string, any> = {}
  modifiedAt?: string = ''
  companyInformation?: CompanyInformation = {}
  lang: string = 'en'
  isStronglyAuthenticated: boolean = false
  marketingConsentGiven?: boolean
  marketingConsentTimestamp?: string = ''
  termsOfServiceConsentGiven: boolean = false
  termsOfServiceConsentTimestamp: string = ''
  credentials: CredentialInfo[] = []
  studyRecords: DatabaseStudyRecordInfo[] = []
  consents: ReplyConsentsPersonalInformation[] = []
}
