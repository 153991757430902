/**
 * Parse JWT
 *
 * @param token
 * @returns {any}
 */
export const parseJwt = (token: any): any => {
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  return false
}

/**
 * Format date from ISO 8601 date string (YYYY-MM-DD)
 * @param {string} dateStringOrDate
 * @returns {string}
 */
export const formatDate = (dateStringOrDate: string | Date, language: string = 'fi'): string => {
  const locale = getLocale(language)

  if (dateStringOrDate instanceof Date) {
    return dateStringOrDate.toLocaleDateString(locale)
  }

  return formatDateString(dateStringOrDate, locale)
}

const formatDateString = (date: string, locale: string) => {
  const parsedDate = Date.parse(date)

  if (isNaN(parsedDate)) {
    return '-'
  }

  const dateObject = new Date(parsedDate)
  return dateObject.toLocaleDateString(locale)
}

const getLocale = (lang: string) => {
  switch (lang) {
    case 'fi':
      return 'fi-FI'
    case 'en':
      return 'en-US'
    case 'sv':
      return 'sv-SE'
    default:
      return 'en-US'
  }
}

export class Quota {
  private _limit: number
  private _used: number
  private _reserved: number
  private _consumption: number
  private _quotaReachedOverride: boolean

  constructor (
    limit?: string | number | null,
    used?: string | number | null,
    reserved?: string | number | null,
    consumption?: string | number | null,
    quotaReachedOverride: boolean = false
  ) {
    this._limit = this.normalizeValue(limit)
    this._used = this.normalizeValue(used)
    this._reserved = this.normalizeValue(reserved)
    this._consumption = this.normalizeValue(consumption)
    this._quotaReachedOverride = quotaReachedOverride
  }

  private normalizeValue (value?: string | number | null | undefined): number {
    if (!value || value === '0') {
      return 0
    }

    if (typeof value === 'string') {
      value = value.replace(',', '.')
    }

    const numericValue = Number(value)

    return isNaN(numericValue)
      ? 0
      : numericValue
  }

  public get limit (): number {
    return this._limit
  }

  public set limit (value: string | number | null | undefined) {
    this._limit = this.normalizeValue(value)
  }

  public get used (): number {
    return this._used
  }

  public set used (value: string | number | null | undefined) {
    this._used = this.normalizeValue(value)
  }

  public get reserved (): number {
    return this._reserved
  }

  public set reserved (value: string | number | null | undefined) {
    this._reserved = this.normalizeValue(value)
  }

  public get consumption (): number {
    return this._consumption
  }

  public set consumption (value: string | number | null | undefined) {
    this._consumption = this.normalizeValue(value)
  }

  public get quotaReachedOverride (): boolean {
    return this._quotaReachedOverride
  }

  public set quotaReachedOverride (value: boolean) {
    this._quotaReachedOverride = value
  }

  public get remaining (): number {
    return this.limit - this.used - this.reserved
  }

  public get isCalculatedQuotaReached (): boolean {
    if (this.isUnlimited) {
      return false
    }

    return this.remaining <= 0
  }

  public get isQuotaReached (): boolean {
    return this.quotaReachedOverride || this.isCalculatedQuotaReached
  }

  public get canConsume (): boolean {
    if (this.quotaReachedOverride) {
      return false
    }

    if (this.isUnlimited) {
      return true
    }

    return this.remaining - this.consumption >= 0
  }

  public get isUnlimited (): boolean {
    return this.limit === 0
  }
}
