import { Button } from 'components/ui/button'
import { Content } from 'components/ui/content'
import { TypographyP } from 'components/ui/typography'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useRegisterFlow from 'pages/register/useRegisterFlow'
import { useAppContext } from 'contexts/AppContext'
import { type CacheLocation, PopupCancelledError, PopupTimeoutError } from '@auth0/auth0-spa-js'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogTitle, AlertDialogHeader, AlertDialogFooter } from 'components/ui/alert-dialog'
import { useNavigate } from 'react-router-dom'
import { useCurrentUserContext } from 'contexts/CurrentUserContext'
import { Box } from 'components/ui/box'
import { useNotifications } from 'contexts/NotificationContext'
import { Section } from 'components/ui/section'
import { IamApi } from 'ext/IamApi'
import { useApiError } from 'hooks/useApiError'
import LazyLoading from 'components/LazyLoading'

/**
 * This page is used to select the authentication method for the user during the login flow
 * and strongly authenticate the user if needed.
 * Login flow continues to additional information page.
 */

const options = {
  iam_domain: process.env.REACT_APP_iam_domain ?? '',
  iam_client_id: process.env.REACT_APP_iam_client_id ?? '',
  admin_role: '',
  iam_redirect_uri: process.env.REACT_APP_iam_redirect_uri ?? '',
  APIGATEWAY_DOMAIN: process.env.REACT_APP_APIGATEWAY_DOMAIN ?? '',
  APIGATEWAY_XAPIKEY: process.env.REACT_APP_APIGATEWAY_XAPIKEY ?? '',
  APIGATEWAY_BASE_PATH: '/v1',
  APIGATEWAY_AUDIENCE: 'DelegatedAdminAPI',
  APP_ASSETS_URI: '',
  cacheLocation: 'memory' as CacheLocation
}

export default function ChooseAuthenticationMethodPage () {
  const { t, i18n } = useTranslation()
  const { api } = useAppContext()
  const { token, profile, refetchProfile } = useCurrentUserContext()
  const { tokenData, state, sessionToken } = useRegisterFlow()
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)
  const navigate = useNavigate()
  const { createNotification, dismissNotification } = useNotifications()
  const { showErrorToast } = useApiError()

  const continueToAdditionalInformationAction = useCallback(async () => {
    if (tokenData?.continue_uri) {
      // "continue_uri" is from the Auth0 Login flow, "Redirect to Strong Authentication" action, token payload
      window.location.replace(`${tokenData.continue_uri}?state=${state}&lang=${i18n.language}`)
    }
  }, [i18n.language, state, tokenData.continue_uri])

  const userId = tokenData.sub ?? profile.userId

  const selectStrongAuthentication = useCallback(async () => {
    const strongAuthIamApi = new IamApi()
    await strongAuthIamApi.initApi(options)
    setIsInProgress(true)

    if (!!sessionToken || !!token) {
      try {
        const currentToken = sessionToken != null ? sessionToken : token ?? ''
        const strongAuthTempProfile = await strongAuthIamApi.loginWithStrongAuth(userId, i18n.language)
        await api.strongAuthEnrich(currentToken, userId, strongAuthTempProfile.data.sub)

        // Logout the temporary user that is just used for getting the data from strong authentication
        await strongAuthIamApi.logout({
          openUrl: false
        })

        if (tokenData?.continue_uri) {
          // "continue_uri" is from the Auth0 Login flow, "Redirect to Strong Authentication" action, token payload
          window.location.replace(`${tokenData.continue_uri}?state=${state}`)
        } else {
          dismissNotification({ externalId: 'strong-auth' })

          createNotification({
            message: 'strongAuthentication.success',
            externalId: 'strong-auth-success',
            context: 'home'
          })

          refetchProfile()
          navigate('/')
        }
      } catch (error: any) {
        setIsInProgress(false)
        if (error instanceof PopupTimeoutError) {
          error.popup.close()
          setErrorDialogOpen(true)
        } else if (error instanceof PopupCancelledError) {
          // This is thrown when closing the popup window using a native closing method like the close button in the window corner.
          // The popup is already closed.
          // We don't have to do anything else here in this case.
        } else if (error?.error === 'access_denied') {
          // Cancelling the flow with the cancel button results in an error object that looks something like this:
          // {"error":"access_denied","error_description":"You interrupted the identification. The login process was not completed."}
          // The popup is already closed.
          // We don't have to do anything here in this case
        } else {
          // This can happen when the user tries to strongly authenticate with credentials already assigned to some other auth0 user.
          showErrorToast(error)
        }
      }
    }
  }, [sessionToken, token, userId, i18n.language, api, tokenData.continue_uri, state, dismissNotification, createNotification, refetchProfile, navigate, showErrorToast])

  const selectManualAuthentication = useCallback(async () => {
    // TODO: Should we show some sort of manual authentication info page here?
    await continueToAdditionalInformationAction()
  }, [continueToAdditionalInformationAction])

  const alreadySignedIn = token

  if (!tokenData && !token) {
    return (
      <Content>
        <TypographyP>
          {t('generic.error.noToken')}
        </TypographyP>
      </Content>
    )
  }

  return (
    <Content>
      {isInProgress
        ? <LazyLoading />
        : (
          <div className='mx-auto max-w-lg flex flex-col'>
            <Box>
              <TypographyP className='mb-8'>
                {alreadySignedIn
                  ? t('selectAuthenticationMethod.description.signedIn')
                  : t('selectAuthenticationMethod.description')}
              </TypographyP>

              <div className='flex flex-col sm:flex-row sm:justify-end gap-4 sm:gap-2'>
                {alreadySignedIn
                  ? (
                // This is shown for example when clicking on the notification that reminds you to strongly authenticate
                    <Button onClick={() => { navigate('/strong-auth/manual-instructions') }} variant='outline'>
                      {t('selectAuthenticationMethod.manual.signedIn')}
                    </Button>
                  )
                  : (
                // This happens immediately after providing your email to the Auth0 sign up dialog
                    <Button onClick={async () => { await selectManualAuthentication() }} variant='outline'>
                      {t('selectAuthenticationMethod.manual')}
                    </Button>
                  )}

                <Button onClick={async () => { await selectStrongAuthentication() }}>
                  {t('selectAuthenticationMethod.strong')}
                </Button>
              </div>
            </Box>
          </div>)}

      <ErrorDialog open={errorDialogOpen} onOpenChange={setErrorDialogOpen} />
    </Content>
  )
}

const ErrorDialog = ({ open, onOpenChange }: { open: boolean, onOpenChange: any }) => {
  const { t } = useTranslation()

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('selectAuthenticationMethod.strongAuthentication.timeout')}
          </AlertDialogTitle>

          <AlertDialogDescription>
            {t('selectAuthenticationMethod.strongAuthentication.retry')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('selectAuthenticationMethod.strongAuthentication.continue')}
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export const ManualVerificationInstructions = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Section>
      <Box className='max-w-lg mx-auto'>
        <div className='flex flex-col space-y-4'>
          <h1 className='text-xl font-bold'>
            {t('strongAuthentication.manualVerificationTitle')}
          </h1>

          <TypographyP>
            {t('register.manualVerificationInstructions')}
          </TypographyP>

          <div className='flex flex-col md:flex-row md:justify-end'>
            <Button onClick={() => { navigate('/') }}>
              {t('register.success.cta')}
            </Button>
          </div>
        </div>
      </Box>
    </Section>
  )
}
